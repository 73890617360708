<template>
  <svg
    width="26"
    height="30"
    viewBox="0 0 26 30"
    fill="#6B6B6B"
    xmlns="http://www.w3.org/2000/svg"
    :class="[customClass.toString()]"
  >
    <path
      d="M15.4449 17.1434C18.7437 17.7615 22.217 16.2838 23.9902 13.2126C26.2389 9.31767 24.9011 4.32484 21.0062 2.07613C17.1113 -0.172586 12.1185 1.16524 9.86976 5.06012C8.09661 8.13132 8.5535 11.8781 10.7381 14.426L7.78288 19.5446L3.07607 16.8271L0.35859 21.534L5.0654 24.2514L3.70666 26.6048L8.41347 29.3223L15.4449 17.1434ZM14.5766 7.7776C15.3239 6.48323 16.9943 6.03563 18.2887 6.78294C19.5831 7.53024 20.0307 9.20071 19.2834 10.4951C18.5361 11.7895 16.8656 12.2371 15.5712 11.4897C14.2769 10.7424 13.8293 9.07197 14.5766 7.7776Z"
    />
  </svg>
</template>

<script>
export default {
  name: "IconKey",
  components: {},
  props: {
    customClass: {
      type: String,
      default: "",
    },
  },
};
</script>
